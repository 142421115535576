import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  constructor(private httpClient: HttpClient) {}
  downloadFile$(downloadParams: {
    url: string;
    params: HttpParams;
    fileName: string;
    fileType: string;
  }): Observable<void> {
    const { url, params, fileName, fileType } = downloadParams;

    return this.httpClient.get(url, { params, responseType: 'blob' }).pipe(
      tap((response) => {
        const blob = new Blob([response], { type: fileType });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }),
      map(() => undefined)
    );
  }
}
